<template>
  <div class="md:grid referrals gap-4">
    <Card class="mt-4 referral-cards">
      <h4 class="text-black text-lg font-semibold">
        Most Recent Referred Merchants
      </h4>
      <EmptyState
        class="empty"
        description="You have not successfully onboarded any merchant"
        v-if="!loading && !allMerchants.length"
      />
      <MerchantReferrals
        v-else
        :getMerchants="allMerchants"
        :loading="loading"
      />
    </Card>
    <Card class="mt-4 referral-cards">
      <h4 class="text-black text-lg font-semibold">
        Most Recent Referred Customers
      </h4>
      <EmptyState
        class="empty"
        description="You have not successfully onboarded any customer"
        v-if="!loading && !allCustomers.length"
      />
      <CustomerReferrals
        v-else
        :getCustomers="allCustomers"
        :loading="loading"
      />
    </Card>
  </div>
</template>

<script>
import CustomerReferrals from "./Customer.vue";
import MerchantReferrals from "./Merchant.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    CustomerReferrals,
    MerchantReferrals,
    EmptyState: () => import("@/UI/EmptyState"),
  },
  data() {
    return {
      loading: false,
      params: {
        page: 0,
        pageSize: 10,
        from: "",
        to: "",
      },
    };
  },
  computed: {
    ...mapState({
      allMerchants: (state) => state?.merchants.merchants,
      allCustomers: (state) => state?.customers.customers,
    }),
  },
  mounted() {
    this.getMerchants();
    this.getCustomers();
  },
  methods: {
    ...mapActions("merchants", ["getAllMerchants"]),
    ...mapActions("customers", ["getAllCustomers"]),
    getMerchants() {
      this.loading = true;
      this.getAllMerchants({
        params: this.params,
      })
        .then((res) => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getCustomers() {
      this.loading = true;
      this.getAllCustomers({
        params: this.params,
      })
        .then((res) => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.referrals {
  grid-template-columns: 50% 1fr;
}
.referral-cards {
  min-height: 25rem;
}
@media (max-width: 768px) {
  .referral-cards {
    min-height: 0px;
  }
}
</style>