<template>
  <div class="login-container h-full grid place-items-center w-10/12 mx-auto">
    <div class="w-full">
      <img
        class="max-h-8 mx-auto mb-6 max-w-full mt-4 md:mt-0"
        src="https://res.cloudinary.com/zillaafrica/image/upload/v1623528889/customer/Zilla_Updated_1_rjqezm.svg"
        alt=""
      />
      <Card
        class="w-full border rounded-lg bg-white border-lightPurple p-8 shadow"
      >
        <h4 class="font-bold md:text-xl text-xl text-center">Partner Login</h4>
        <form @submit.prevent="loginUser">
          <!-- <Input
            type="text"
            class="mt-14"
            placeholder="What's your phone number?"
            v-model="partner.username"
            width="w-full"
            id="username"
            errorText="Please enter a valid phone number"
            :validation="rules.username"
            @valid="valid.username = $event"
          /> -->
          <vue-tel-input
            v-model="partner.username"
            :enabled-country-code="false"
            :input-options="{ showDialCode: false, tabindex: 0 }"
            :required="true"
            :valid-characters-only="true"
            :mode="'international'"
            @input="checkPhoneNumber"
            defaultCountry="NG"
            class="mt-14"
            wrapper-classes="border-0 focus:outline-none pl-12 text-brand_dark_blue"
            placeholder="What's your Phone number?"
          ></vue-tel-input>
          <p
            v-if="phoneIsValid === false"
            class="text-brandRed text-sm pl-2 py-1"
          >
            Invalid phone number for the country code selected
          </p>
          <Input
            class="mt-11"
            type="password"
            placeholder="What's your password"
            revealPassword
            v-model="partner.secret"
            width="w-full"
            id="password"
            errorText="Password field cannot be empty"
            :validation="rules.secret"
            @valid="valid.secret = $event"
          />
          <div></div>
          <Button
            text="Login"
            width="w-full mt-12"
            :loading="loading"
            :disabled="disableButton"
          />
          <span class="text-black mt-5 flex justify-end underline text-sm">
            <router-link to="/forgot-password">Forgot password?</router-link>
          </span>
        </form>
      </Card>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
import { VueTelInput } from "vue-tel-input";
export default {
  components: {
    Input,
    Button,
    VueTelInput,
  },
  data() {
    return {
      partner: {
        username: "",
        secret: "",
      },
      loading: false,
      valid: {
        username: false,
        secret: false,
      },
      phoneIsValid: true,
      canSubmit: false,
    };
  },
  // computed
  computed: {
    rules() {
      return {
        username: this.canSubmit === true,
        secret: this.partner.secret.length !== 0,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  methods: {
    ...mapActions("auth", ["login"]),
    ...mapActions("notification", ["showAlert"]),
    loginUser() {
      this.loading = true;
      this.login(this.partner)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    checkPhoneNumber(number, obj) {
      this.phoneIsValid = obj.valid;
      if (this.phoneIsValid) {
        this.canSubmit = true;
      } else {
        this.canSubmit = false;
      }
    },
  },
  // },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style scoped>
.login-container {
  min-height: 100vh;
  max-width: 30rem;
}

/* @media (max-width: 768px) {
    .login-container {
      min-height: calc(100vh - 15rem);
      max-width: 100%;
    }
  } */
</style>
