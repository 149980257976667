import cookies from "vue-cookies";

export default {
  user: {
    cashoutAccountBankId: "",
    cashoutAccountName: "",
    cashoutAccountNumber: ""
  },
  registrationId: "",
  token: cookies.get("token"),
  tokenExpire: cookies.get("tokenExpire"),
  status: "",
  currentTab: "personal",
  password_id: "",
};
