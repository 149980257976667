<template>
  <div class="login-container h-full grid place-items-center w-10/12 mx-auto">
    <div class="w-full">
      <img
        class="max-h-8 mx-auto mb-6 max-w-full mt-4 md:mt-0"
        src="https://res.cloudinary.com/zillaafrica/image/upload/v1623528889/customer/Zilla_Updated_1_rjqezm.svg"
        alt=""
      />
      <Card
        class="w-full border rounded-lg bg-white border-lightPurple p-8 shadow"
      >
        <h4 class="font-bold md:text-xl text-xl text-center">
          Complete your partner registration
        </h4>
        <form @submit.prevent="onboardPartner">
          <Input
            class="mt-11"
            type="password"
            placeholder="What's your password"
            revealPassword
            v-model="partner.password"
            width="w-full"
            id="password"
            errorText="Password must have at least 8 characters, a lowercase letter, an uppercase letter, a number and a special character"
            :validation="rules.password"
            required
            @valid="valid.password = $event"
          />
          <Input
            class="mt-11"
            type="password"
            placeholder="Confirm your password"
            revealPassword
            v-model="confirmPassword"
            width="w-full"
            id="confirm-password"
            errorText="Passwords do not match"
            :validation="rules.confirmPassword"
            @valid="valid.confirmPassword = $event"
          />
          <div></div>
          <Button
            text="Finish"
            width="w-full mt-12"
            :loading="loading"
            :disabled="disableButton"
          />
        </form>
      </Card>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
export default {
  components: {
    Input,
    Button,
  },
  data() {
    return {
      partner: {
        password: "",
        partnerId: "",
      },
      confirmPassword: "",
      loading: false,
      valid: {
        password: false,
      },
    };
  },
  // computed: {
  computed: {
    rules() {
      return {
        password:
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
            this.partner.password
          ),
        confirmPassword: this.confirmPassword === this.partner.password,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  methods: {
    ...mapActions("auth", ["completeRegistration"]),
    ...mapActions("notification", ["showToast", "showAlert"]),
    onboardPartner() {
      this.partner.partnerId = this.$route.params.id;
      this.loading = true;
      this.completeRegistration(this.partner)
        .then(() => {
          this.loading = false;
          this.showToast({
            description: "Your onboarding was successful",
            display: true,
            icon: "success",
          });
          setTimeout(() => {
            this.$router.push("/login");
          }, 1000);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.showAlert({
              display: true,
              type: "success",
              description: `${err.response.data.message}, please check the link`,
            });
          }
          this.loading = false;
        });
    },
  },
  // },
};
</script>
<style scoped>
.login-container {
  min-height: 100vh;
  max-width: 30rem;
}
</style>
