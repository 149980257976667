<template>
  <div class="body-container gap-4">
    <Card class="h-full">
      <h3 class="text-black font-semibold text-lg">My Profile</h3>
      <Hr />
      <div class="grid text-left grid-cols-1 md:grid-cols-2">
        <div class="flex items-center">
          <div
            class="rounded-full border inline-block border-lightPurple p-2 mr-4"
          >
            <img
              class="h-20 w-20 rounded-full"
              src="@/assets/icons/profile.png"
              alt=""
            />
          </div>
          <div>
            <h4 class="text-black font-semibold">{{ partner.firstName }} {{ partner.lastName }}</h4>
            <p class="font-semibold text-lightPurple-2 text-sm mt-1 md:mt-0">
              {{ partner.phoneNumber }}
            </p>
          </div>
        </div>
        <!-- <div class="flex justify-center mt-2 md:mt-0 md:justify-end">
          <div>
            <Button
              text="Complete Profile"
              radius="rounded-3xl"
              p="py-2 px-6"
            />
          </div>
        </div> -->
      </div>
      <div class="nav mt-8">
        <div
          class="settings md:grid grid-cols-5 justify-items-center overflow-x"
        >
          <p
            class="
              tab
              text-lightPurple-2
              cursor-pointer
              relative
              transition-all
            "
            :class="{ personal: settingType === 'personal' }"
            @click="handleClick('personal')"
          >
            Personal Info
          </p>
          <p
            v-if="partner.commissionFlag === 'COMMISSIONED'"
            class="
              tab
              text-lightPurple-2
              relative
              cursor-pointer
              transition-all
            "
            :class="{ personal: settingType === 'account' }"
            @click="handleClick('account')"
          >
            Bank Account
          </p>
        </div>
        <Hr />
        <Card class="mt-4" p="p-0 md:p-4">
          <transition name="slideUp" mode="out-in">
            <PersonalInfo v-if="settingType === 'personal'" />
            <Account v-if="settingType === 'account'" />
          </transition>
        </Card>
      </div>
    </Card>
    <div>
      <Card>
        <h3 class="text-black ont-bold text-lg">Security and Privacy</h3>
        <Hr />
        <ChangePassword />
      </Card>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import PersonalInfo from "./PersonalInfo";
import ChangePassword from "./ChangePassword";
import Account from "./Account";
import Hr from "@/UI/Hr";
import { Button } from "@/UI/Button";

export default {
  components: {
    Hr,
    Button,
    PersonalInfo,
    Account,
    ChangePassword,
  },
  data: () => ({
    loader: true,
  }),
  computed: {
    ...mapState({
      partner: (state) => state?.auth?.user,
      settingType: (state) => state?.auth?.currentTab,
    }),
  },

  methods: {
    ...mapActions("auth", ["setCurrentTab"]),
    ...mapActions("notification", ["showToast"]),
    ...mapActions("loading", ["setLoading"]),
    handleClick(newTab) {
      this.setCurrentTab(newTab);
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.body-container {
  display: grid;
  grid-template-columns: 70% 1fr;
  min-height: calc(100vh - 10rem);
}
.personal {
  font-weight: 600;
}
.personal:after {
  /* content: ""; */
  position: absolute;
  content: "";
  height: 4px;
  border-radius: 4px;
  bottom: -0.65rem;
  margin: 0 auto;
  left: 0;
  /* right: 0; */
  width: 100%;
  background: #6956c8;
  /* optional animation */
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
@media (max-width: 768px) {
  .body-container {
    grid-template-columns: 100%;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .settings {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;
  }
  .settings .tab {
    flex: 0 0 auto;
    margin-right: 2px;
    padding: 5px 10px;
  }

  .personal {
    background: #6956c8;
    border-radius: 20px;
    color: #fff;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .personal:after {
    bottom: 0;
    background: none;
  }
}
</style>
