<template>
  <div>
    <div class="flex items-center justify-between">
      <h4 class="font-semibold text-lg text-black">Your profile</h4>
      <!-- <div>
        <Button
          v-if="disabled"
          text="Change Account"
          p="py-2 px-6"
          radius="rounded-3xl"
          fontSize="text-sm"
          background="bg-lightRed"
          color="text-brandRed"
          @click="disabled === false"
        />
        <Button
          v-if="disabled === false"
          text="Save info"
          p="py-2 px-6"
          radius="rounded-3xl"
          width="w-28"
          fontSize="text-sm"
          :loading="loading"
          @click="updateBusinessInfo()"
        />
      </div> -->
    </div>
    <div class="mt-4">
      <Input
        width="w-full"
        placeholder="First Name"
        class="mt-14"
        :disabled="disabled"
        v-model="partner.firstName"
      />
      <Input
        width="w-full"
        placeholder="Last Name"
        class="mt-11"
        :disabled="disabled"
        v-model="partner.lastName"
      />
      <Input
        width="w-full"
        placeholder="Phone Number"
        class="mt-11"
        :disabled="disabled"
        v-model="partner.phoneNumber"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Input } from "@/UI/Input";
export default {
  components: {
    Input,
  },
  data: () => ({
    disabled: true,
    notAvailable: "N/A",
  }),
  computed: {
    ...mapState({
      partner: (state) => state?.auth?.user,
    }),
  },
};
</script>
