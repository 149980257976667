<template>
  <Onboard />
</template>
<script>
  import Onboard from "@/components/Auth/Onboarding.vue";
  export default {
    components: {
      Onboard,
    },
  };
</script>
