<template>
  <div class="login-container h-full grid place-items-center w-10/12 mx-auto">
    <div class="w-full">
      <div class="w-12/12 container-register pb-4 mx-auto">
        <Card
          class="
            w-full
            border
            rounded-lg
            bg-white
            border-lightPurple
            p-8
            shadow
          "
        >
          <transition name="slideUp" mode="out-in">
            <div class="register-form" v-if="!showSuccess" key="form">
              <h2
                class="
                  text-lg text-center
                  lg:text-left
                  md:text-2xl
                  font-semibold
                  mt-10
                  lg:mt-0
                "
              >
                Create a password you can remember
              </h2>

              <Input
                id="password"
                type="password"
                width="w-full"
                placeholder="Create a password"
                class="mt-14"
                :revealPassword="true"
                v-model="password"
                errorText="Password must have at least 8 characters, a lowercase letter, an uppercase letter, a number and a special character"
                :validation="rules.password"
                required
                @valid="valid.password = $event"
              />
              <Input
                id="confirm password"
                type="password"
                width="w-full"
                placeholder="Confirm password"
                class="mt-11"
                :revealPassword="true"
                v-model="confirm_password"
                errorText="Passwords do not match"
                :validation="rules.confirm_password"
                @valid="valid.confirm_password = $event"
              />

              <Button
                text="Change password"
                class="mt-12"
                @click="resetPassword"
                :loading="loading"
                width="w-full"
                :disabled="disableButton"
                shadow
              />
              <div class="mt-10">
                <p class="text-sm text-center text-grey font-semibold">
                  Already have an Account?
                  <router-link to="/login"
                    ><span class="text-black">Sign In</span></router-link
                  >
                </p>
              </div>
            </div>
            <div v-if="showSuccess" class="message h-full" key="message">
              <img
                class="h-32 w-32 mx-auto"
                src="https://res.cloudinary.com/zillaafrica/image/upload/v1624100575/customer/Group_707_1_tpsp0o.svg"
                alt="success"
              />
              <h2 class="text-center mt-8 text-lg lg:text-2xl font-semibold">
                Password reset successful
              </h2>

              <p class="mt-2.5 text-grey text-center">
                The zilla experience awaits you
              </p>

              <Button
                text="Login"
                class="mt-14"
                width="w-full"
                @click="$router.push('/login')"
              />
            </div>
          </transition>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Input } from "../../../UI/Input";
import { Button } from "@/UI/Button";
export default {
  components: {
    Input,
    Button,
  },
  data() {
    return {
      password: "",
      confirm_password: "",
      showSuccess: false,
      loading: false,
      valid: {
        password: false,
        confirm_password: false,
      },
    };
  },
  computed: {
    ...mapState({
      id: (state) => state.auth.password_id,
    }),
    rules() {
      return {
        password:
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
            this.password
          ),
        confirm_password: this.password === this.confirm_password,
      };
    },
    disableButton() {
      return Object.values(this.valid).includes(false);
    },
  },
  methods: {
    ...mapActions("auth", ["completePasswordReset"]),
    ...mapActions("notification", ["showAlert"]),
    resetPassword() {
      this.loading = true;
      this.completePasswordReset({
        passwordResetId: this.id,
        newPassword: this.password,
      })
        .then(() => {
          this.showSuccess = true;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
@import "../../../assets/styles/animation.css";
/* .register-container {
    min-height: 100vh;
  } */
.register-container h3 {
  font-size: 35px;
}
.register-container p {
  line-height: 30.15px;
}
.container-register {
  max-width: 33.831rem;
}
.resend-otp {
  position: absolute;
  top: 0;
  right: 0;
}
.login-container {
  min-height: 100vh;
  max-width: 30rem;
}
</style>
