<template>
  <div class="min-h-full navigation overflow-hidden bg-lightPurple">
    <slot />
  </div>
</template>
<script>
  export default {
    props: {
      nav: String,
    },
    computed: {
      showNav() {
        if (this.$route.path === "/login" || this.$route.path === "/register") {
          return false;
        } else {
          return true;
        }
      },
    },
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";
</style>
