<template>
  <div>
    <div class="md:block hidden">
      <table id="template-table" class="w-full table-auto mt-4">
        <thead v-if="getCustomers.length">
          <tr>
            <th class="py-4 text-left">First Name</th>
            <th class="py-4 text-left">Last Name</th>
            <th class="py-4 text-left">Date</th>
          </tr>
        </thead>

        <tbody v-if="loading">
          <tr v-for="index in 6" :key="index" class="py-10 w-full">
            <td v-for="index in 3" :key="index" class="py-2">
              <Skeleton width="80%" height="1.2rem" />
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="customer in getCustomers" :key="customer.id" class="py-4">
            <td class="py-4">
              {{ customer.firstname | capitalize }}
            </td>
            <td class="py-4">
              {{ customer.lastname | capitalize }}
            </td>
            <td class="py-4">
              {{ dayjs(customer.createdAt).format("D MMM YYYY") }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-for="customer in getCustomers" :key="customer.id" class="md:hidden block">
      <div class="flex justify-between py-1 mt-4">
        <div class="grid logo">
          <div>
            <h2 class="font-semibold">
              {{ (customer.firstname + " " + customer.lastname) | capitalize }}
            </h2>
            <h2 class="font-semibold">
              <p class="text-sm text-grey">
                {{ dayjs(customer.createdAt).format("D MMM YYYY") }}
              </p>
            </h2>
          </div>
        </div>
      </div>
      <hr class="bg-lightPurple my-2 border-lightPurple" />
    </div>
    <div class="flex justify-center">
      <Button
        text="View all"
        background="bg-lightPurple"
        color="text-brandPurple"
        class="mt-6"
        p="py-1.5 px-6"
        radius="rounded-2xl"
        fontWeight="font-semibold"
        @click="$router.push('/customer-referrals')"
      />
    </div>
  </div>
</template>

<script>
import { Button } from "@/UI/Button";
import dayjs from "dayjs";
export default {
  computed: {
    dayjs() {
      return dayjs;
    },
  },
  components: {
    Button,
  },
  props: {
    getCustomers: {
      type: Array,
      required: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
</style>