import axios from "@/plugins/axios";

export const CASHOUT_HISTORY = (payload) => {
  return axios.get(`/bnpl/partner/wallet-history`, { params: payload });
};

export const COMMISSION_HISTORY = (payload) => {
  return axios.get(`/bnpl/partner/commission-wallet-history`, { params: payload });
};

export const WITHDRAW_FROM_ZILLA = (data) => {
  return axios.put(`/bnpl/partner/withdraw`,
    data
  );
};

export const GET_DASHBOARD_DETAILS = () => {
  return axios.get(`/bnpl/partner-dashboard`);
};


