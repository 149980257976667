import {
  CASHOUT_HISTORY,
  COMMISSION_HISTORY,
  GET_DASHBOARD_DETAILS
} from "@/utils/api/dashboard.js";

export default {
  getCashoutHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CASHOUT_HISTORY(payload.params)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_CASHOUT_HISTORY", data.data)
            : commit("GET_CASHOUT_HISTORY", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCommissionHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      COMMISSION_HISTORY(payload.params)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_COMMISSION_HISTORY", data.data)
            : commit("GET_COMMISSION_HISTORY", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPartnerDashboard({ commit }) {
    return new Promise((resolve, reject) => {
      GET_DASHBOARD_DETAILS()
        .then(({ data }) => {
          commit("PARTNER_DASHBOARD", data.data)
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
