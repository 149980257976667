export default {
  SET_REGISTRATION(state, registrationId) {
    state.registrationId = registrationId;
  },
  setUserDetails(state, user) {
    state.user = user;
  },
  AUTH_REQUEST(state) {
    state.status = "loading";
  },
  AUTH_SUCCESS(state, token) {
    state.status = "success";
    state.token = token;
  },
  AUTH_ERROR(state) {
    state.status = "error";
  },
  AUTH_LOGOUT(state) {
    state.token = "";
    state.user = "";
    state.tokenExpire = null;
  },
  SET_TOKEN_EXPIRE(state, payload) {
    state.tokenExpire = payload;
  },
  setCurrentTab(state, tab) {
    state.currentTab = tab;
  },
  CHANGE_PASSWORD_ID(state, payload) {
    state.password_id = payload;
  },
  setBankDetails(state, payload) {
    state.user.cashoutAccountName = payload.cashoutAccountName;
    state.user.cashoutAccountBankId = payload.cashoutAccountBankId;
    state.user.cashoutAccountNumber = payload.cashoutAccountNumber;
  },
};
