<template>
  <div class="login-container h-full grid place-items-center w-10/12 mx-auto">
    <div class="w-full">
      <div class="w-11/12 container-register pb-4 mx-auto">
        <Card
          class="
            w-full
            border
            rounded-lg
            bg-white
            border-lightPurple
            p-8
            shadow
          "
        >
          <transition name="slideUp" mode="out-in">
            <div class="register-form" v-if="showMessage" key="form">
              <h2
                class="
                  text-lg text-center
                  lg:text-left
                  md:text-2xl
                  font-semibold
                  mt-6
                  lg:mt-0
                "
              >
                Reset your passsword
              </h2>
              <!-- 
              <Input
                type="email"
                width="w-full"
                placeholder="What's your Phone number?"
                class="mt-14"
                v-model="email"
                errorText="Please enter a valid phone number"
                :validation="rules.email"
                @valid="emailValid = $event"
              /> -->
              <vue-tel-input
                v-model="email"
                :enabled-country-code="false"
                :input-options="{ showDialCode: false, tabindex: 0 }"
                :required="true"
                :valid-characters-only="true"
                :mode="'international'"
                @input="checkPhoneNumber"
                defaultCountry="NG"
                class="mt-14"
                wrapper-classes="border-0 focus:outline-none pl-12 text-brand_dark_blue"
                placeholder="What's your Phone number?"
              ></vue-tel-input>
              <p
                v-if="phoneIsValid === false"
                class="text-brandRed text-sm pl-2 py-1"
              >
                Invalid phone number for the country code selected
              </p>

              <Button
                text="Confirm phone number"
                class="mt-12"
                @click="initiatePasswordReset"
                :shadow="true"
                width="w-full"
                :loading="emailLoading"
                :disabled="disableButton"
              />
              <div class="mt-10">
                <p class="text-sm text-center text-grey font-semibold">
                  Already have an Account?
                  <router-link to="/login" class="text-black"
                    >Sign In</router-link
                  >
                </p>
              </div>
            </div>
            <div class="message h-full" v-else key="message">
              <h2
                class="
                  text-lg text-center
                  lg:text-left
                  md:text-2xl
                  font-semibold
                  mt-6
                "
              >
                <span class="text-grey"> Just to be sure.</span> Are the details
                below yours?
              </h2>
              <p class="text-center lg:text-left text-grey mt-2">
                We sent a secret code to your phone number {{ email }}. Kindly
                provide it below.
              </p>
              <div class="relative">
                <Input
                  type="number"
                  width="w-full"
                  placeholder="Enter OTP"
                  class="mt-14"
                  v-model="otp"
                  errorText="OTP must be 6 characters"
                  :validation="otprules.otp"
                  @valid="otpValid = $event"
                />
                <Button
                  v-if="showResendButton"
                  text="Resend OTP"
                  class="resend-otp z-10"
                  background="bg-lightPurple"
                  color="text-brandPurple"
                  p="px-2.5 py-1"
                  fontSize="text-sm"
                  radius="rounded-3xl"
                  width="w-32"
                  :loading="resendLoading"
                  @click="resendOtp"
                />
              </div>

              <Button
                text="Verify phone number"
                class="mt-8 md:mt-16"
                @click="sendOtp"
                :shadow="true"
                width="w-full"
                :loading="loading"
                :disabled="disableOtpButton"
              />
              <div class="mt-2 md:mt-10">
                <!-- <p class="text-sm text-center text-grey font-semibold">
              I don’t have access to this numbers
            </p> -->
              </div>
            </div>
          </transition>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import { VERIFY_OTP } from "@/utils/api/auth.js";
import { mapActions, mapState } from "vuex";
import { Input } from "../../../UI/Input";
import { Button } from "@/UI/Button";
import { VueTelInput } from "vue-tel-input";
export default {
  components: {
    Input,
    Button,
    VueTelInput,
  },
  data() {
    return {
      email: "",
      otp: "",
      showMessage: true,
      loading: false,
      emailLoading: false,
      resendLoading: false,
      emailValid: false,
      otpValid: false,
      showResendButton: false,
      phoneIsValid: true,
      canSubmit: false,
    };
  },
  computed: {
    ...mapState({
      id: (state) => state.auth.password_id,
    }),
    rules() {
      return {
        email: this.canSubmit === true,
      };
    },
    otprules() {
      return {
        otp: this?.otp?.length === 6,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
    disableOtpButton() {
      return Object.values(this.otprules).includes(false);
    },
  },
  mounted() {
    this.handleDelayOtpButton();
  },
  methods: {
    ...mapActions("auth", [
      "forgotPassword",
      "verifyPasswordResetOtp",
      "setOtpId",
    ]),
    ...mapActions("notification", ["showAlert"]),
    initiatePasswordReset() {
      // console.log("jkrkjt");
      this.emailLoading = true;
      this.forgotPassword({
        username: this.email,
      })
        .then(() => {
          this.showMessage = false;
          this.emailLoading = false;
        })
        .catch(() => {
          this.emailLoading = false;
        });
    },
    sendOtp() {
      this.loading = true;

      this.verifyPasswordResetOtp({
        passwordResetId: this.id,
        otp: this.otp,
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    resendOtp() {
      this.resendLoading = true;
      VERIFY_OTP(this.id)
        .then((res) => {
          this.resendLoading = false;
          this.setOtpId(res.data.data.id);
          this.showAlert({
            display: true,
            type: "success",
            description: `A new otp has been sent to your email address`,
          });
          this.showResendButton = false;
          this.handleDelayOtpButton();
        })
        .catch(() => {
          this.resendLoading = false;
        });
    },
    handleDelayOtpButton() {
      setTimeout(() => {
        this.showResendButton = true;
      }, 60000);
    },
    checkPhoneNumber(number, obj) {
      this.phoneIsValid = obj.valid;
      if (this.phoneIsValid) {
        this.canSubmit = true;
      } else {
        this.canSubmit = false;
      }
    },
  },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style scoped>
@import "../../../assets/styles/animation.css";
/* .register-container {
    min-height: 100vh;
  } */
.register-container h3 {
  font-size: 35px;
}
.register-container p {
  line-height: 30.15px;
}
.container-register {
  max-width: 33.831rem;
}
.resend-otp {
  position: absolute;
  top: 0;
  right: 0;
}
.login-container {
  min-height: 100vh;
  max-width: 30rem;
}
</style>
<style>
.vti__dropdown-list {
  z-index: 20 !important;
}
.vue-tel-input {
  border: 0px;
  border-bottom: 1px solid black !important;
  border-radius: 0px !important;
}
.vue-tel-input:focus-within {
  outline: none !important;
  box-shadow: none !important;
  border-color: black;
}
</style>
