import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeView.vue'
import Login from "../views/login";
import Onboard from "../views/onboard";
import Settings from "@/views/settings";
import ForgotPassword from "../views/ForgotPassword";
import CreatePassword from "@/views/CreatePassword";
import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: '/merchant-referrals',
    name: 'merchant-referrals',
    component: () => import('../views/MerchantReferrals'),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: '/customer-referrals',
    name: 'customer-referrals',
    component: () => import('../views/CustomerReferrals'),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { layout: "default", requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "auth",
      guest: true,
    },
  },
  {
    path: "/complete-registration/:id",
    name: "Onboard",
    component: Onboard,
    meta: {
      layout: "auth",
      guest: true,
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot",
    component: ForgotPassword,
    meta: { layout: "auth", guest: true },
  },
  {
    path: "/create-password",
    name: "Create Password",
    component: CreatePassword,
    meta: { layout: "auth", guest: true },
  },
  {
    path: "*",
    redirect: "/",
    name: "Not Found",
    component: Home,
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const expiryDate = new Date(store?.state?.auth?.tokenExpire);
    const currentDate = new Date().getTime();
    if (store.getters["auth/isAuthenticated"] && expiryDate > currentDate) {
      next();
      return;
    } else {
      next("/login");
    }
    // if it is a guest page
  } else if (to.matched.some((record) => record.meta.guest)) {
    const expiryDate = new Date(store?.state?.auth?.tokenExpire);
    const currentDate = new Date().getTime();
    if (store.getters["auth/isAuthenticated"] && expiryDate > currentDate) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router
