import axios from "axios";
import store from "../store";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // headers: {
  //   "Bypass-Tunnel-Reminder": "test"
  // }
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {

    if (error?.response?.status != 401) {
      if (error?.response?.status != 404) {
        if (error?.response?.data?.message) {
          store.dispatch("notification/showAlert", {
            display: true,
            type: "cancel",
            description: error.response.data.message,
          });
        } else {
          store.dispatch("notification/showAlert", {
            display: true,
            type: "cancel",
            description:
              "We are sorry this happened. Please check your internet connection and try again",
            title: "An error occurred",
          });
        }
      }
    }

    if (error.response.status === 401) {
      store.dispatch("auth/logout");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
