export default {
  GET_CASHOUT_HISTORY(state, payload) {
    state.cashoutHistory = payload;
  },
  ADD_CASHOUT_HISTORY(state, payload) {
    state.cashoutHistory.push(...payload);
  },
  GET_COMMISSION_HISTORY(state, payload) {
    state.commissionHistory = payload;
  },
  ADD_COMMISSION_HISTORY(state, payload) {
    state.ccommissionHistory.push(...payload);
  },
  PARTNER_DASHBOARD(state, payload) {
    // console.log(payload);
    state.partnerDashboard = payload;
  },
};
