import cookies from "vue-cookies";
import router from "@/router";
import axios from "@/plugins/axios";
import {
  LOGIN, ONBOARD_PARTNER, NEW_PASSWORD, FORGOT_PASSWORD,
  PASSWORD_OTP,
  PASSWORD_RESET,
} from "@/utils/api/auth";

export default {
  login({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      commit("AUTH_REQUEST");
      LOGIN(user)
        .then((response) => {
          const token = response?.data?.data?.authResponse?.token;
          const tokenExpire = response?.data?.data?.authResponse?.tokenExpireAt;

          cookies.set("token", token);
          cookies.set("tokenExpire", tokenExpire);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          // if user has an acc belongs to a customer
          if (response?.data?.data?.userCategory === "CUSTOMER") {
            commit("setUserDetails", {
              email: user.username,
              password: user.secret,
            });
            dispatch(
              "notification/showAlert",
              {
                description: "This account belongs to a customer",
                display: true,
                type: "error",
                buttonText: "Okay",
              },
              { root: true }
            );
            reject(response);
          } else if (response?.data?.data?.userCategory === "MERCHANT_USER") {
            dispatch(
              "notification/showAlert",
              {
                description:
                  "This account belongs to a merchant",
                display: true,
                type: "error",
                buttonText: "Okay",
              },
              { root: true }
            );
            reject(response);
          } else {
            commit("AUTH_SUCCESS", token);
            commit("setUserDetails", response.data.data.partnerLoginResDto);
            commit("SET_TOKEN_EXPIRE", tokenExpire);
            router.push("/");
            resolve(response);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            dispatch(
              "notification/showAlert",
              {
                display: true,
                type: "error",
                description: error?.response?.data?.message,
              },
              { root: true }
            );
          }
          commit("AUTH_ERROR", error);
          reject(error);
        });
    });
  },
  completeRegistration({ commit }, user) {
    return new Promise((resolve, reject) => {
      ONBOARD_PARTNER(user)
        .then((response) => {
          // commit("SET_REGISTRATION", response.data.data.registrationId);
          // router.push("/otp");
          // console.log(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout({ commit }) {
    commit("AUTH_LOGOUT");
    cookies.remove("token");
    delete axios.defaults.headers.common["Authorization"];
    router.push("/login");
  },
  setCurrentTab({ commit }, payload) {
    commit("setCurrentTab", payload);
  },

  updateAccountInfo({ commit }, payload) {
    commit("setBankDetails", payload);
  },

  changePassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      NEW_PASSWORD(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  forgotPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FORGOT_PASSWORD(payload)
        .then((response) => {
          commit("CHANGE_PASSWORD_ID", response.data.data.id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setOtpId({ commit }, payload) {
    commit("CHANGE_PASSWORD_ID", payload);
  },

  verifyPasswordResetOtp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      PASSWORD_OTP(payload)
        .then((response) => {
          router.push("/create-password");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  completePasswordReset({ commit }, payload) {
    return new Promise((resolve, reject) => {
      PASSWORD_RESET(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
