<template>
  <div>
    <div class="md:block hidden">
      <table id="template-table" class="w-full table-auto mt-4">
        <thead v-if="getMerchants.length">
          <tr>
            <th class="py-4 text-left">Merchant Name</th>
            <th class="py-4 text-left">Website</th>
            <th class="py-4 text-left">Date</th>
          </tr>
        </thead>

        <tbody v-if="loading">
          <tr v-for="index in 6" :key="index" class="py-10 w-full">
            <td v-for="index in 3" :key="index" class="py-2">
              <Skeleton width="80%" height="1.2rem" />
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="merchant in getMerchants" :key="merchant.id" class="py-4">
            <td class="py-4">
              {{ merchant.name | capitalize }}
            </td>
            <td class="py-4">
              <a
                :href="merchant.businessWebsite"
                v-if="merchant.businessWebsite"
                target="blank"
                >{{ merchant.businessWebsite }}</a
              >
              <span v-else>Not Available</span>
            </td>
            <td class="py-4">
              {{ dayjs(merchant.createdAt).format("D MMM YYYY") }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="md:hidden block"
      v-for="merchant in getMerchants"
      :key="merchant.id"
    >
      <div class="flex justify-between py-1 mt-4">
        <div class="grid logo">
          <div>
            <h2 class="font-semibold">
              {{ merchant.name | capitalize }}
            </h2>
            <p class="text-sm text-lightPurple-2">
              <a
                :href="merchant.businessWebsite"
                v-if="merchant.businessWebsite"
                target="blank"
                >{{ merchant.businessWebsite }}</a
              >
              <span v-else>Not Available</span>
            </p>
          </div>
        </div>
        <div class="text-right">
          <h2 class="font-semibold">
            <p class="text-sm text-grey">
              {{ dayjs(merchant.createdAt).format("D MMM YYYY") }}
            </p>
          </h2>
        </div>
      </div>
      <hr class="bg-lightPurple my-2 border-lightPurple" />
    </div>
    <div class="flex justify-center">
      <Button
        text="View all"
        background="bg-lightPurple"
        color="text-brandPurple"
        class="mt-6"
        p="py-1.5 px-6"
        radius="rounded-2xl"
        fontWeight="font-semibold"
        @click="$router.push('/merchant-referrals')"
      />
    </div>
  </div>
</template>

<script>
import { Button } from "@/UI/Button";
import dayjs from "dayjs";
export default {
  computed: {
    dayjs() {
      return dayjs;
    },
  },
  components: {
    Button,
  },
  props: {
    getMerchants: {
      type: Array,
      required: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
</style>