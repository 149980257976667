<template>
  <div>
    <transition name="fade" mode="out-in">
      <div
        class="w-full md:grid md:grid-cols-3 gap-4"
        v-if="loading"
        key="card"
      >
        <Card v-for="i in 3" :key="i">
          <Skeleton height="1rem" width="40%" />
          <Skeleton height="1.5rem" width="30%" class="mt-2" />
          <Skeleton height="1rem" width="80%" class="mt-4" />
          <Skeleton height="1rem" width="30%" />
          <Skeleton height="2rem" width="11rem" class="mt-5" radius="10px" />
        </Card>
      </div>
      <div
        class="md:grid gap-4"
        :class="[
          partner.commissionFlag === 'COMMISSIONED'
            ? 'md:grid-cols-4'
            : 'md:grid-cols-2',
        ]"
        v-else
        key="no card"
      >
        <Card
          v-if="partner.commissionFlag === 'COMMISSIONED'"
          class="bg-brandPurple col-span-1 mt-4 md:mt-0"
        >
          <div>
            <p class="text-white uppercase">Commission wallet balance</p>
            <div class="flex items-center mt-6">
              <h3 class="text-2xl text-white font-bold">
                {{ partner.commissionWallet.balance | formatMoney }}
              </h3>
            </div>

            <div class="mt-4">
              <div class="mr-3">
                <SecondaryButton
                  text="View commission history"
                  class="mt-6"
                  color="text-brandPurple"
                  background="bg-white"
                  p="px-3 py-2"
                  fontSize="text-sm"
                  @click="showCommissionHistory = true"
                />
              </div>
            </div>
          </div>
        </Card>
        <Card class="bg-lightPurple col-span-1 md:mt-0 mt-4" shadow>
          <div>
            <p class="uppercase">
              Wallet <br />
              balance
            </p>
            <div class="flex items-center mt-6">
              <h3 class="text-2xl font-bold">
                {{ partner.wallet.balance | formatMoney }}
              </h3>
            </div>

            <div class="flex items-center">
              <div class="mr-3">
                <SecondaryButton
                  :disabled="partner.commissionFlag === 'NON_COMMISSIONED'"
                  text="Withdraw"
                  class="mt-6"
                  color="text-brandPurple"
                  background="bg-white"
                  p="p-2"
                  fontSize="text-sm"
                  @click="showWithdrawModal = true"
                />

                <!-- <SecondaryButton
                  v-else
                  text="Non Commissioned"
                  class="mt-6"
                  color="text-brandPurple"
                  background="bg-white"
                  p="px-4 py-2"
                  fontSize="text-xs md:text-sm"
                /> -->
              </div>
              <div
                v-if="partner.commissionFlag === 'COMMISSIONED'"
                class="flex items-center md:py-2 cursor-pointer rounded-full mt-6"
                @click="showCashoutHistory = true"
              >
                <p class="text-sm mr-1">Withdraw history</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="6"
                  viewBox="0 0 11 6"
                  fill="none"
                >
                  <path
                    d="M8.17463 2.33322H0.834635C0.467969 2.33322 0.167969 2.63322 0.167969 2.99989C0.167969 3.36656 0.467969 3.66656 0.834635 3.66656H8.17463V4.85989C8.17463 5.15989 8.53464 5.30656 8.7413 5.09322L10.5946 3.23322C10.7213 3.09989 10.7213 2.89322 10.5946 2.75989L8.7413 0.899889C8.53464 0.686556 8.17463 0.839889 8.17463 1.13322V2.33322Z"
                    fill="#000"
                  />
                </svg>
              </div>
            </div>
          </div>
        </Card>
        <Card
          class="bg-white md:mt-0 mt-4"
          :class="[
            partner.commissionFlag === 'COMMISSIONED'
              ? 'col-span-2'
              : '',
          ]"
        >
          <div>
            <div class="md:flex items-center justify-between">
              <p class="text-lightPurple-2">Referral Details</p>
              <h3 class="text-xl mr-2 text-black font-bold">
                {{ user.referralCode }}
              </h3>
            </div>

            <!-- <p class="mt-1 md:mt-2.5 text-sm text-blaack-2">
              These are your unique referral links
            </p> -->
            <div class="md:flex justify-between">
              <input
                class="bg-lightPurple p-2 mt-2 rounded w-full outline-none"
                v-model="customerReferral"
                disabled
              />
              <transition name="fade" mode="out-in">
                <SecondaryButton
                  text="Copy shopper's link"
                  color="text-white"
                  background="bg-brandPurple"
                  p="py-3 md:py-2 px-2.5"
                  fontSize="text-xs md:text-sm"
                  class="md:ml-3 mt-2"
                  width="w-full md:w-44"
                  @click="copy(customerReferral)"
                />
              </transition>
            </div>

            <div class="md:flex justify-between mt-1">
              <input
                class="bg-lightPurple p-2 mt-2 rounded w-full outline-none"
                v-model="merchantReferral"
                disabled
              />
              <transition name="fade" mode="out-in">
                <SecondaryButton
                  text="Copy merchant's link"
                  color="text-white"
                  background="bg-brandPurple"
                  p="py-3 md:py-2 px-2.5"
                  fontSize="text-xs md:text-sm"
                  class="md:ml-3 mt-2"
                  width="w-full md:w-44"
                  @click="copy(merchantReferral)"
                />
              </transition>
            </div>

            <div class="md:flex justify-between mt-1">
              <input
                class="bg-lightPurple p-2 mt-2 rounded w-full outline-none"
                v-model="websiteReferral"
                disabled
              />
              <transition name="fade" mode="out-in">
                <SecondaryButton
                  text="Copy website link"
                  color="text-white"
                  background="bg-brandPurple"
                  p="py-3 md:py-2 px-2.5"
                  fontSize="text-xs md:text-sm"
                  class="md:ml-3 mt-2"
                  width="w-full md:w-44"
                  @click="copy(websiteReferral)"
                />
              </transition>
            </div>
          </div>
        </Card>
      </div>
    </transition>

    <div>
      <Referrals />
    </div>

    <Modal :display="showWithdrawModal" @close="showWithdrawModal = false">
      <div v-if="noCashoutAccount" class="my-10">
        <img
          src="https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1631996266/checkout/50_1_o6ver8.png"
          alt="Order Invalid"
          class="mx-auto h-36"
        />
        <p class="text-center text-xl font-semibold my-10">
          You need to provide a bank account for withdrawal
        </p>
        <Button
          text="Add an account"
          :shadow="true"
          p="p-3"
          class="mt-12 mx-auto"
          width="w-full"
          @click="addAccount()"
        />
      </div>
      <div class="form" v-else>
        <h4 class="text-lg font-semibold text-black">WIthdraw from wallet</h4>
        <CurrencyInput
          width="w-full"
          placeholder="Withdrawal Amount"
          class="mt-12"
          v-model="withdraw.amount"
        />
        <Input
          width="w-full"
          placeholder="Notes"
          class="mt-10"
          v-model="withdraw.note"
        />
        <Input
          width="w-full"
          placeholder="Cashout Bank"
          class="mt-10"
          disabled="disabled"
          :value="bank.number + ' ' + bank.bank.prettyName"
        />
        <!-- <p class="pending my-4 text-sm">
          Zilla will charge you N100 for this withdrawal
        </p> -->
        <Button
          text="Withdraw"
          width="w-full"
          class="my-12"
          @click="withdrawFromZilla()"
          :loading="withdrawLoading"
        />
      </div>
    </Modal>

    <Modal
      :display="showCashoutHistory"
      @close="showCashoutHistory = false"
      title="Withdrawal History"
    >
      <CashoutHistory />
    </Modal>
    <Modal
      :display="showCommissionHistory"
      @close="showCommissionHistory = false"
      title="Your Commission History"
    >
      <CommissionHistory />
    </Modal>
  </div>
</template>
<script>
import {
  GET_ACCOUNT_DETAILS,
  GET_USER_CASHOUT_ACCOUNT,
} from "@/utils/api/settings.js";
import { WITHDRAW_FROM_ZILLA } from "@/utils/api/dashboard.js";
import { GET_PARTNER_WALLET } from "@/utils/api/auth.js";
import { mapActions, mapState } from "vuex";
import { Button, SecondaryButton } from "@/UI/Button";
import { Modal } from "@/UI/Modals";
import { Input, CurrencyInput } from "@/UI/Input";
import CashoutHistory from "./CashoutHistory";
import CommissionHistory from "./CommissionHistory";
import Referrals from "@/components/Home/Referrals";
import copy from "@/mixins/copy";
export default {
  mixins: [copy],
  components: {
    SecondaryButton,
    Input,
    CurrencyInput,
    Button,
    Modal,
    CashoutHistory,
    CommissionHistory,
    Referrals,
  },

  computed: {
    ...mapState({
      partner: (state) => state?.partner?.partnerDashboard,
      user: (state) => state?.auth?.user,
    }),
  },
  created() {
    this.getAccountInfo();
    this.getPartnerDashboard();
    this.referral = `${process.env.VUE_APP_WEBSITE}/ref/${this.user.referralCode}`;
    this.customerReferral = `${process.env.VUE_APP_CUSTOMER_URL}/register?ref=${this.user.referralCode}`;
    this.merchantReferral = `${process.env.VUE_APP_MERCHANT_URL}/register?ref=${this.user.referralCode}`;
    this.websiteReferral = `${process.env.VUE_APP_WEBSITE}/?ref=${this.user.referralCode}`;
    this.getWalletInfo();
  },
  data() {
    return {
      showWithdrawModal: false,
      loading: false,
      referral: "",
      customerReferral: "",
      merchantReferral: "",
      websiteReferral: "",
      withdraw: {},
      withdrawLoading: false,
      wallet: {},
      bank: {
        bank: {},
      },
      showCashoutHistory: false,
      showCommissionHistory: false,
      noCashoutAccount: false,
    };
  },
  methods: {
    ...mapActions("partner", ["getPartnerDashboard"]),
    ...mapActions("auth", ["setCurrentTab"]),
    addAccount() {
      this.setCurrentTab("account");
      this.$router.push("/settings");
    },
    getWalletInfo() {
      this.loading = true;
      GET_PARTNER_WALLET()
        .then((response) => {
          if (response.data.data) {
            this.loading = false;
            this.wallet = response.data.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getAccountInfo() {
      GET_ACCOUNT_DETAILS()
        .then((response) => {
          this.getBankAccountInfo(response.data.data.id);
        })
        .catch(() => {});
    },
    getBankAccountInfo(partnerId) {
      GET_USER_CASHOUT_ACCOUNT(partnerId)
        .then((response) => {
          if (response.data.data) {
            this.bank = response.data.data;
          }
        })
        .catch((err) => {
          if (err.response.data.message === "Cashout bank account not found") {
            this.noCashoutAccount = true;
          }
        });
    },
    withdrawFromZilla() {
      this.withdrawLoading = true;
      WITHDRAW_FROM_ZILLA(this.withdraw)
        .then(() => {
          this.withdrawLoading = false;
          this.showWithdrawModal = false;
          if (res.data.data.status === "PROCESSING") {
            this.showAlert({
              display: true,
              type: "success",
              description: `Successful! Processing your withdrawal`,
            });
          } else {
            this.showAlert({
              description:
                "Your attempt to withdraw from your wallet failed, please contact support.",
              display: true,
              type: "error",
              title: "Payment failed",
            });
            this.loading = false;
          }
          this.withdraw = {};
          this.getWalletInfo();
        })
        .catch(() => {
          this.withdrawLoading = false;
          this.showWithdrawModal = false;
          // this.showAlert({
          //   display: true,
          //   type: "error",
          //   description: `Withdrawal failed`,
          // });
        });
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.firstCard {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.direction {
  transform: rotate(-90deg);
}
.repayment-balance {
  border-radius: 10px;
  background-color: #fff;
}
.resend-otp {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
