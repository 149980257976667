<template>
  <div class="relative">
    <div class="flex justify-between items-center">
      <p v-if="!name.length">{{ text }}</p>
      <p class>{{ name }}</p>

      <label class="" :class="[width ? width : null]" :for="id">
        <div>
          <!-- <img
          class="h-8 w-8 mx-auto"
          src="@/assets/icons/uploadImage.svg"
          alt=""
        /> -->
          <!-- <Button text="Upload" /> -->
          <div class="lds-dual-ring" v-if="loading"></div>

          <p class="text-brandGreen font-semibold">Upload</p>
        </div>
        <input
          class="hidden"
          type="file"
          :id="id"
          @change="uploadImage($event)"
        />
      </label>
    </div>

    <div class="line w-full relative"></div>
    <!-- 
    <AnimateHeight>
      <div
        class="p-4 rounded flex justify-between mt-2 bg-dashGrey"
        v-show="uploadSuccess"
      >
        <div class="flex items-center">
          <div class="mr-2">
          </div>
          <div>
            <p class="text-brandGreen text-sm">{{ name }}</p>
            <p class="text-grey text-sm">{{ size }}KB</p>
          </div>
        </div>
     
      </div>
    </AnimateHeight> -->
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import { UPLOAD_IMAGE } from "@/utils/api/media";
  import AnimateHeight from "../../AnimateHeight";
  import { Button } from "@/UI/Button";
  export default {
    components: {
      AnimateHeight,
      Button,
    },
    props: [
      "id",
      "value",
      "text",
      "validation",
      "fileType",
      "width",
      "fileSize",
    ],
    data() {
      return {
        loading: false,
        name: "",
        size: "",
        image: "",
        uploadSuccess: false,
        imageData: "",
        // accept: ['.png'],
      };
    },
    methods: {
      ...mapActions("notification", ["showToast"]),
      valid() {},
      uploadImage(e) {
        // console.log(e);

        // console.log(this.size);
        if (this.fileType) {
          let valid;
          // try to get the file extention;
          let fileName = e.target.files[0].name.split(".");
          let name = fileName[fileName.length - 1];
          if (this.fileType.includes(name)) {
            // no upload greater than 1mb
            if (this.size <= 5000) {
              this.loading = true;
              const formData = new FormData();
              this.name = e.target.files[0].name;
              this.size = e.target.files[0].size / 1000;
              this.image = e.target.name;
              formData.append("file", e.target.files[0]);
              UPLOAD_IMAGE(formData)
                .then((res) => {
                  //   console.log(res.data.data);
                  this.$emit("valid", true);
                  this.loading = false;
                  this.uploadSuccess = true;
                  this.showToast({
                    description: "File uploaded successfully",
                    display: true,
                    icon: "success",
                  });
                  this.imageData = res.data.data;
                  this.$emit("upload", res.data.data);

                  // console.log(res)
                })
                .catch(() => {
                  this.showToast({
                    icon: "error",
                    title: " 😌",
                    display: true,
                    description: "Upload Failed, Please try again",
                  });
                  this.loading = false;
                  // console.log(err)
                });
            } else {
              this.showToast({
                display: true,
                description: "File size cannot be more then 5MB",
                icon: "error",
              });
            }
          } else {
            this.showToast({
              display: true,
              description: "Please upload a supported file format",
              icon: "error",
            });
            valid = false;
          }
        }
      },
      // cancel() {
      //   this.$emit("valid", false);
      //   // console.log(this.name)
      //   const formData = new FormData();
      //   formData.append("key", this.name);
      //   this.loading = true;
      //   this.$axios
      //     .delete(
      //       `https://storage.svc.youverify.co/api/v1/storage/delete_file`,
      //       formData
      //     )
      //     .then((res) => {
      //       this.loading = false;
      //       console.log(res);
      //     })
      //     .catch((err) => {
      //       this.loading = false;
      //       console.log(err);
      //     });
      //   this.$emit("cancel");
      //   this.uploadSuccess = false;
      // },
    },
  };
</script>
<style scoped>
  label {
    /* background-color: #fff1f5;
    padding: 0.5rem 1rem;
    text-align: center; */
    display: inline-block;
  }
  label p {
    color: #c70039;
    background-color: #fff1f5;
    padding: 0.5rem 1.3rem;
    text-align: center;
    border-radius: 100px;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    font-weight: semibold;
    /* position: absolute; */
    right: 0;
    top: 0;
  }
  .line {
    /* border: 1px solid black; */
    background: black;
    height: 1px;
    margin-top: 0.3rem;
  }
  .bg {
    background-color: #eefcff;
  }
  .slide-enter-to,
  .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }
  .slide-enter,
  .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
  .lds-dual-ring {
    display: inline-block;
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
    top: 0;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 25px;
    height: 25px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid #023a59;
    border-color: #023a59 transparent #023a59 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
