// import axios from "@/plugins/axios";
import { GET_PARTNER_CUSTOMERS } from "@/utils/api/customers";

export default {
  getAllCustomers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_PARTNER_CUSTOMERS(payload)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_CUSTOMERS", data.data)
            : commit("GET_CUSTOMERS", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
