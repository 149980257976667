<template>
  <div class="home">
    <HelloWorld />
  </div>
</template>

<script>
import HelloWorld from '@/components/Home'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
