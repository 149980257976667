<template>
  <transition :name="currentAnimation">
    <div class="fixed modal-container" v-if="display" @click="clickTarget">
      <transition :name="currentAnimation">
        <div class="modal-body bg-white p-6" v-if="display">
          <div class="flex justify-end">
            <img
              class="h-3 w-3 cursor-pointer"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1623525601/customer/Union_yx6ulk.svg"
              alt="cancel"
              @click="closeDisplay"
            />
          </div>
          <div class="completed mt-8">
            <!-- <img
              class="h-32 w-32 mx-auto"
              src="https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1624100575/customer/Group_707_1_tpsp0o.svg"
              alt="success"
              v-if="type === 'success'"
            /> -->
            <!-- <img
              class="h-28 w-28 mx-auto"
              src="https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1629045373/Group_12919_be2tin.svg"
              alt="success"
              v-if="type === 'error'"
            /> -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="131"
              height="130"
              viewBox="0 0 131 130"
              fill="none"
              class="h-32 w-32 mx-auto"
              v-if="type === 'success'"
            >
              <circle cx="65.5" cy="64.885" r="64.885" fill="#6A30C3" />
              <circle
                cx="65.5"
                cy="64.885"
                r="64.885"
                fill="url(#paint0_linear_1_8514)"
              />
              <path
                d="M34.0682 71.9458L51.2778 87.1396L90.3074 38.9212"
                stroke="white"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_8514"
                  x1="-811.589"
                  y1="622.896"
                  x2="436.487"
                  y2="1015.37"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.38059" stop-color="#C6003B" />
                  <stop offset="0.469915" stop-color="#6A30C3" />
                  <stop offset="0.667674" stop-color="#63DFDF" />
                </linearGradient>
              </defs>
            </svg>

            <svg
              v-if="type === 'error'"
              class="h-32 w-32 mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              width="131"
              height="130"
              viewBox="0 0 131 130"
              fill="none"
            >
              <circle cx="65.5" cy="64.885" r="64.885" fill="#6A30C3" />
              <circle
                cx="65.5"
                cy="64.885"
                r="64.885"
                fill="url(#paint0_linear_1_9402)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M120.615 65C120.615 95.3757 95.9907 120 65.615 120C35.2393 120 10.615 95.3757 10.615 65C10.615 34.6243 35.2393 10 65.615 10C95.9907 10 120.615 34.6243 120.615 65ZM65.1864 74.5571C62.0293 74.5571 59.615 72.1429 59.615 68.9857V40.5714C59.615 37.4143 62.0293 35 65.1864 35C68.3436 35 70.7578 37.4143 70.7578 40.5714V68.9857C70.7578 72.1429 68.3436 74.5571 65.1864 74.5571ZM61.2864 93.3147C62.215 94.429 63.7007 94.9862 65.1864 94.9862C66.6721 94.9862 68.1578 94.429 69.4578 93.3147C70.5721 92.3862 71.1293 90.9004 71.1293 89.4147C71.1293 89.229 71.0828 89.0433 71.0364 88.8576C70.99 88.6719 70.9436 88.4862 70.9436 88.3004C70.8507 88.1147 70.8043 87.929 70.7578 87.7433C70.7114 87.5576 70.665 87.3719 70.5721 87.1862C70.4793 87.0005 70.3864 86.8612 70.2936 86.7219C70.2007 86.5826 70.1078 86.4433 70.015 86.2576C69.8293 85.8862 69.4578 85.5147 69.2721 85.329C67.2293 83.2862 63.515 83.2862 61.4721 85.329C61.1007 85.7004 60.915 85.8862 60.7293 86.2576C60.6364 86.4433 60.5436 86.5826 60.4507 86.7219C60.3578 86.8612 60.265 87.0005 60.1721 87.1862C59.9864 87.5576 59.8007 87.929 59.8007 88.3004C59.615 88.6719 59.615 89.0433 59.615 89.4147C59.615 90.9004 60.1721 92.2004 61.2864 93.3147Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_9402"
                  x1="-811.589"
                  y1="622.896"
                  x2="436.487"
                  y2="1015.37"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.38059" stop-color="#C6003B" />
                  <stop offset="0.469915" stop-color="#6A30C3" />
                  <stop offset="0.667674" stop-color="#63DFDF" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              v-if="type === 'cancel'"
              class="h-32 w-32 mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              width="131"
              height="130"
              viewBox="0 0 131 130"
              fill="none"
            >
              <circle cx="65.5" cy="65" r="65" fill="#FC3C11" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M44.4345 38.1907C42.8481 36.6044 40.2761 36.6044 38.6898 38.1907C37.1034 39.7771 37.1034 42.3491 38.6898 43.9355L59.7549 65.0006L38.692 86.0634C37.1056 87.6498 37.1056 90.2218 38.692 91.8081C40.2784 93.3945 42.8504 93.3945 44.4367 91.8081L65.4996 70.7453L86.5624 91.8081C88.1488 93.3945 90.7208 93.3945 92.3072 91.8081C93.8935 90.2218 93.8935 87.6498 92.3072 86.0634L71.2443 65.0006L92.3094 43.9355C93.8958 42.3491 93.8958 39.7771 92.3094 38.1907C90.723 36.6044 88.151 36.6044 86.5647 38.1907L65.4996 59.2558L44.4345 38.1907Z"
                fill="white"
              />
            </svg>

            <h3
              class="text-lg md:text-xl font-bold mt-6 text-black text-center"
            >
              {{ description }}
            </h3>
            <p class="text-center text-sm warning">{{ info }}</p>
            <Button
              class="mt-12"
              :text="buttonText ? buttonText : 'Close'"
              @click="closeDisplay"
              width="w-full"
            />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
import { mapState, mapActions } from "vuex";
// import Button from "@/UI/Button";
import { Button } from "@/UI/Button";

export default {
  components: {
    Button,
  },
  data: () => ({
    matchWidth: "slideUp",
  }),

  computed: {
    ...mapState("notification", {
      type: (state) => state.modal.type,
      description: (state) => state.modal.description,
      display: (state) => state.modal.display,
      title: (state) => state.modal.title,
      buttonText: (state) => state?.modal?.buttonText,
      callback: (state) => state.modal.callback,
      info: (state) => state.modal.info,
    }),
    currentAnimation() {
      if (window.matchMedia("(max-width: 1024px)").matches) {
        return "slideUp";
      } else {
        return "fade";
      }
    },
  },
  watch: {
    display(value) {
      // console.log(value, "dislay value");
      if (value) {
        document.body.style.overflow = "hidden";
        // console.log(document.body.style.overflow);
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  // mounted() {
  //   console.log(this.display, "display");
  // },
  methods: {
    ...mapActions("notification", ["showAlert"]),
    closeDisplay() {
      this.showAlert({
        description: "",
        display: false,
        type: "",
        title: "",
        buttonText: "Close",
        callback: null,
      });
      if (this.callback) {
        this.callback();
      }
      // this.$emit("close");
    },
    clickTarget(e) {
      if (e.target.className.includes("modal-container")) {
        this.closeDisplay();
      }
    },
  },
  // beforeDestroy() {
  //   document.body.style.overflow = "auto";
  // },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.modal-container {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(61, 61, 61, 0.8);
  z-index: 20;
}
.modal-body {
  position: fixed;
  bottom: 10px;
  /* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
  border-radius: 20px;
  max-height: 80vh;
  overflow-y: auto;
  width: 95%;
  left: 2.5%;
  right: 2.5%;
}

@media (min-width: 1024px) {
  .modal-container {
    display: grid;
    place-items: center;
  }

  .modal-body {
    position: initial;
    width: 30%;
    max-width: 28rem;
    border-radius: 20px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s cubic-bezier(0.45, 0.25, 0.6, 0.95);
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate(0%, -10px);
  opacity: 0;
}
/* .slideUp-enter-active,
  .slideUp-leave-active {
    transition: all 0.4s ease-out;
    transform: translateX(0);
  }
  .slideUp-enter,
  .slideUp-leave-to {
    opacity: 0;

    transform: translateY(90%);
  } */
</style>
