// import axios from "@/plugins/axios";
import { GET_PARTNER_MERCHANTS } from "@/utils/api/merchants";

export default {
  getAllMerchants({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_PARTNER_MERCHANTS(payload)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_MERCHANTS", data.data)
            : commit("GET_MERCHANTS", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
