<template>
  <div>
    <p
      class="font-semibold text-black mt-6 cursor-pointer"
      @click="showModal = true"
    >
      Change password
    </p>

    <Modal :display="showModal" @close="showModal = false">
      <div class="form">
        <h4 class="text-lg font-semibold text-black">Change Password</h4>
        <Input
          type="password"
          placeholder="Current password"
          class="mt-8"
          width="w-full"
          id="password"
          revealPassword
          v-model="password.currentPassword"
          errorText="Current password cannot be empty"
          :validation="rules.currentPassword"
          @valid="valid.password = $event"
          required
        />
        <Input
          type="password"
          placeholder="New Password"
          class="mt-8"
          width="w-full"
          id="new_password"
          revealPassword
          v-model="password.newPassword"
          errorText="Password must have at least 8 characters, a lowercase letter, an uppercase letter, a number and a special character"
          :validation="rules.newPassword"
          required
          @valid="valid.new_password = $event"
        />
        <Input
          type="password"
          placeholder="Confirm new password"
          revealPassword
          class="mt-8"
          errorText="Passwords do not match"
          v-model="confirm_password"
          width="w-full"
          id="confirm_password"
          :validation="password.newPassword === confirm_password"
          @valid="valid.confirm_password = $event"
        />
        <Button
          text="Change Password"
          width="w-full"
          class="my-12"
          :shadow="true"
          @click="updatePassword()"
          :disabled="disableButton"
          :loading="loading"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
import { Modal } from "@/UI/Modals";
export default {
  components: {
    Input,
    Button,
    Modal,
  },
  data: () => ({
    showModal: false,
    password: {
      currentPassword: "",
      newPassword: "",
    },
    loading: false,
    confirm_password: "",
    valid: {
      password: false,
      confirm_password: false,
      new_password: false,
    },
  }),
  computed: {
    rules() {
      return {
        currentPassword: this.password.currentPassword.length !== 0,
        newPassword:
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
            this.password.newPassword
          ),
        confirm_password: this.password.newPassword === this.confirm_password,
      };
    },
    disableButton() {
      return Object.values(this.valid).includes(false);
    },
  },

  methods: {
    ...mapActions("notification", ["showAlert"]),
    ...mapActions("auth", ["changePassword"]),

    updatePassword() {
      this.loading = true;
      this.changePassword(this.password)
        .then(() => {
          this.loading = false;
          this.showModal = false;
          this.showAlert({
            display: true,
            type: "success",
            description: `Your password has been changed successfully`,
          });
          this.password.currentPassword = "";
          this.password.newPassword = "";
          this.confirm_password = "";
        })
        .catch(() => {
          this.showModal = false;
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
