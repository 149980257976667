import axios from "@/plugins/axios";


// ACCOUNT ENDPOINTS
export const GET_ALL_BANKS = () => {
  return axios.get(`/bnpl/lookup/all-banks`);
};

export const GET_BANK_ACCOUNT_NAME = (data) => {
  return axios.post(`/bnpl/lookup/account-number`, data);
};

export const SAVE_ACCOUNT_INFO = (data) => {
  return axios.put(`/bnpl/partner/update-cashout-account`, data);
};


export const GET_ACCOUNT_DETAILS = () => {
  return axios.get(`/bnpl/partner/logged-in-user`);
};

export const GET_USER_CASHOUT_ACCOUNT = (id) => {
  return axios.get(`/bnpl/cashout-bank-account`, {
    params: {
      ownerId: `${id}`
    }
  });
};
