<template>
  <div class="navbar items-center h-20 md:h-24 pr-4">
    <div class="hidden logo h-24 md:flex items-center">
      <div class="h-8">
        <img
          class="max-h-full max-w-full pl-8"
          src="https://res.cloudinary.com/zillaafrica/image/upload/v1623528889/customer/Zilla_Updated_1_rjqezm.svg"
          alt=""
        />
      </div>
    </div>
    <div class="flex w-full container justify-between items-center pl-4">
      <div>
        <h3 class="md:text-lg font-bold text-darkBlue">
          Hello {{ activeUser.firstName | capitalize }},
          <br class="md:hidden" />
          <span class="font-normal">{{ greeting }} </span>
        </h3>
      </div>
      <div class="flex items-center">
        <!-- <svg width="29" height="30" fill="none" class="mr-4 hidden md:block">
          <path
            d="M18.504 24.906l-.037-.366h6.817c1.364 0 2.042-1.653 1.081-2.617-1.495-1.501-2.329-2.604-2.799-3.911-.473-1.314-.587-2.86-.587-5.271 0-5.098-4.116-9.233-9.196-9.233s-9.196 4.135-9.196 9.233c0 2.6-.07 3.539-.416 4.692h0c-.427 1.433-1.309 2.82-2.971 4.49h0c-.96.964-.283 2.617 1.08 2.617h6.817l-.037.366v.025c0 2.618 2.113 4.741 4.722 4.741 2.61 0 4.723-2.123 4.723-4.74v-.013l-.001-.013zm-4.721 2.983a2.952 2.952 0 01-2.945-2.946l.04-.404h5.81l.04.405a2.952 2.952 0 01-2.945 2.945zm10.91-5.133H2.873c1.666-1.761 2.545-3.28 3.002-4.81.49-1.64.49-3.276.49-5.184v-.021c0-4.115 3.322-7.45 7.418-7.45 4.095 0 7.418 3.335 7.418 7.45v.02c0 1.91 0 3.545.49 5.184.457 1.532 1.336 3.05 3.003 4.811z"
            fill="#000"
            stroke="#8A74C0"
            stroke-width=".5"
          />
          <circle cx="22.304" cy="6.696" r="6.696" fill="#FF5722" />
        </svg> -->
        <!-- <router-link to="/settings"> -->
        <div
          class="relative cursor-pointer"
          @click.stop="showLogout = !showLogout"
        >
          <div class="icon">
            <transition name="fade">
              <img
                v-show="showImage"
                class="icon rounded-full"
                :src="profilePicture"
                alt="profile picture"
                @error="handleImageError"
                @load="showImage = true"
              />
            </transition>
          </div>

          <transition name="slideUp">
            <div
              class="logout-container text-center bg-white p-4 flex items-center"
              v-if="showLogout"
            >
              <svg class="mr-2" width="19" height="20" fill="none">
                <path
                  d="M3.491 17.258a8.405 8.405 0 005.989 2.467h.013a8.532 8.532 0 006.014-2.466A8.309 8.309 0 0018 11.315a8.364 8.364 0 00-2.493-5.99.472.472 0 00-.664 0 .464.464 0 000 .66 7.44 7.44 0 012.218 5.328 7.386 7.386 0 01-2.215 5.284 7.595 7.595 0 01-5.354 2.195H9.48A7.471 7.471 0 014.157 16.6a7.432 7.432 0 01-2.218-5.306c0-2.003.787-3.887 2.217-5.307a.464.464 0 000-.66.472.472 0 00-.665 0A8.355 8.355 0 001 11.293c0 2.251.885 4.37 2.491 5.965z"
                  fill="#523F14"
                  stroke="#523F14"
                  stroke-width=".5"
                />
                <path
                  d="M9.377 1c-.272 0-.492.207-.492.462V9.9c0 .255.221.462.493.462s.492-.207.492-.462V1.462c0-.255-.221-.462-.493-.462z"
                  fill="#523F14"
                  stroke="#523F14"
                  stroke-width=".5"
                />
              </svg>
              <p
                class="warning text-sm cursor-pointer"
                v-click-outside="handleOptionsOutside"
                @click.stop="logout"
              >
                Logout from account
              </p>
            </div>
          </transition>
        </div>
        <!-- </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState({
      activeUser: (state) => state?.auth?.user,
    }),
    greeting() {
      let day = new Date();
      let hr = day.getHours();
      if (hr >= 0 && hr < 12) {
        return "Good Morning!";
      } else if (hr == 12) {
        return "Good Afternoon";
      } else if (hr >= 12 && hr <= 16) {
        return "Good Afternoon";
      } else {
        return "Good Evening!";
      }
    },
    // userBio: (state) => state?.userInfo?.userBio,
    // profilePicture() {
    //   return (
    //     `${process.env.VUE_APP_BASE_URL}/media/image/${this.activeUser?.customerFacialImageId}` ||
    //     `https://res.cloudinary.com/zillaafrica/image/upload/v1623454461/customer/Group_513_hnarq6.svg`
    //   );
    // },
  },
  data: () => ({
    showLogout: false,
    profilePicture: "",
    showImage: false,
  }),
  mounted() {
    if (this.activeUser?.customerFacialImageId) {
      this.profilePicture = `${process.env.VUE_APP_BASE_URL}/media/image/${this.activeUser?.customerFacialImageId}`;
    } else {
      this.profilePicture = require("@/assets/icons/profile.png");
    }
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    toggleLogout() {},
    handleOptionsOutside() {
      this.showLogout = false;
    },
    handleImageError() {
      this.profilePicture = require("@/assets/icons/profile.png");
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.navbar {
  background-color: #f3f3f6;
}
.logout-container {
  position: absolute;
  top: 2.5rem;
  width: 15rem;
  right: 0;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(175, 177, 225, 0.15);
}
.navbar {
  display: grid;
  grid-template-columns: 265px 1fr;
}

@media (max-width: 768px) {
  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
