import axios from "@/plugins/axios";

export const LOGIN = (data) => {
  return axios.post(`/bnpl/auth/partner`, data);
};

export const ONBOARD_PARTNER = (data) => {
  return axios.put(`/bnpl/partner-registration/complete-registration`, data);
};

export const NEW_PASSWORD = (data) => {
  return axios.put(`/volt/password-change/change-password`, data);
};

export const FORGOT_PASSWORD = (data) => {
  return axios.put(`/volt/password-change/initiate-password-reset`, data);
};

export const PASSWORD_OTP = (data) => {
  return axios.put(`/volt/password-change/submit-password-reset-otp`, data);
};

export const PASSWORD_RESET = (data) => {
  return axios.put(`/volt/password-change/complete-password-reset`, data);
};
export const VERIFY_OTP = (id) => {
  return axios.put(`/volt/password-change/${id}/resend-otp`);
};

export const GET_PARTNER_WALLET = () => {
  return axios.get(`/bnpl/partner/wallet`);
};

